<template>
  <div>
    <div v-if="!invoice">
      <form @submit.prevent="onAdd">
        <div class="columns">
          <div class="column">
            <div class="field">
              <b-radio
                  native-value="note"
                  v-model="type"
              >
                Nota
              </b-radio>
              <b-radio
                  v-model="type"
                  native-value="local"
              >
                Orden Local
              </b-radio>
              <b-input v-model="order"></b-input>
            </div>
          </div>
          <div class="column">
            <br>
            <b-button @click="onAdd">Agregar</b-button>
          </div>
        </div>
      </form>
      <div class="is-divider" data-content="Datos Fiscales" v-if="orders.length !== 0"></div>
      <form-patient-tax-data
          :data="taxData"
          :errors="errorsTaxData"
          @setTaxData="setTaxData"
          v-if="orders.length !== 0"
          :edit="hasTaxData"></form-patient-tax-data>
      <br>
      <b-field label="Observaciones" v-if="orders.length !== 0">
        <b-input v-model="observations" type="textarea"></b-input>
      </b-field>
      <br>
      <InvoiceTable
          :can-drop="true"
          @drop-item="onDropItem"
          :orders="orders"/>
      <br>
      <div class="columns">
        <div class="column">
          <b-button class="button is-primary" @click="onSubmit">Generar Factura</b-button>
        </div>
      </div>
    </div>
    <div v-if="invoice">
      <div class="columns is-multiline">
        <div class="column is-12">
          <h1 class="title">Factura generada</h1>
          <p>
            <strong>RFC: </strong> {{ invoice.rfc }} <br>
            <strong>Serie: </strong> {{ invoice.serie }} <br>
            <strong>Folio: </strong> {{ invoice.folio }} <br>
            <strong>Fecha: </strong> {{ invoice.datetime }} <br>
          </p>
        </div>
        <div class="column">
          <b-button @click="onRefresh">Generar Nueva Factura Grupal</b-button>
        </div>
        <div class="column is-12">
          <iframe class="lapi__iframe" :src="`${endpoint}/order-invoice/${invoice.uuid}/pdf/download`"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormPatientTaxData from "@/components/patient/FormPatientTaxData";
import InvoiceTable from "@/components/invoice/InvoiceTable";

export default {
  name: "InvoiceGroup",
  components: {FormPatientTaxData, InvoiceTable},
  props: {
    year: {
      type: String | Number
    },
    branch_office: {
      type: String | Number
    }
  },
  data() {
    return {
      orders: [],
      order: '',
      type: 'local',
      taxData: {
        use: 'G03'
      },
      hasTaxData: false,
      errorsTaxData: {},
      invoice: null,
      observations: '',
      in_process: false
    }
  },
  computed: {
    endpoint() {
      return process.env.VUE_APP_API_URL;
    }
  },
  methods: {
    onAdd() {
      const founds = this.orders.filter((item) => {
        return item.no == this.order
      });
      if (founds.length === 0) {
        this.$loader.show();
        this.$http.get(`/orders/${this.$auth.user().active_branch_office}/${this.type}/${this.year}/${this.order}?add=taxData&minified=true`).then(({data}) => {
          const order = data.data;
          if (order.can_be_billed) {
            this.order = '';
            this.orders.push(order);
            this.orders = Object.assign([], this.orders);
            if (JSON.stringify(this.taxData) === JSON.stringify({use:'G03'}) && order.patient.tax_data) {
              this.taxData = order.patient.tax_data;
              this.taxData.use = 'D01';
              this.taxData.sat_version = '3.3';
            }
          } else {
            this.$toast.error("Esta orden ya cuenta con una factura");
          }
        }).catch(error => {
          this.$alfalab.showError(error);
        }).finally(() => {
          this.$loader.hide();
        });
      } else {
        this.$toast.error("La orden indicada ya se encuentra registrada");
      }
    },
    onSubmit() {
      if(!this.in_process){
        this.in_process = true;
        this.$loader.show();
        const data = {
          orders: this.orders.map(order => {
            return order.id;
          }),
          tax_data: this.taxData,
          branch_office: this.branch_office
        }
        if(this.observations){
          data['tax_data']['observations'] = this.observations;
        }

        this.$http.post('orders-billed', data).then(({data}) => {
          this.$toast.success(data.message);
          this.invoice = data.invoice;
        }).catch(error => {
          this.$alfalab.showError(error);
        }).finally(() => {
          this.$loader.hide();
          this.in_process = false;
        })
      }
    },
    setTaxData(taxdata) {
      this.taxData = taxdata;
    },
    onRefresh() {
      this.invoice = null;
      this.taxData = {};
    },
    onDropItem(index) {
      const item = this.orders[index];
      this.orders.splice(index, 1);
      this.orders = this.orders.slice(0);
    }
  }
}
</script>

<style scoped>

.lapi__iframe {
  width: 100%;
  height: 60vh;
}
</style>
