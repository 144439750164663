<template>
  <div>
    <div v-if="!creditNote">
      <div class="columns">
        <div class="column">
          <div class="field">
            <b-radio
                native-value="patient"
                @input="invoices = []"
                v-model="type"
            >
              Paciente
            </b-radio>
            <b-radio
                v-model="type"
                @input="invoices = []"
                native-value="generalPublic"
            >
              Público en General
            </b-radio>
          </div>
        </div>
      </div>
      <div class="column is-12 is-full" v-if="type === 'patient' && !patient.id">
        <select-patient
            :patient.sync="patient"
            class="columns"></select-patient>
      </div>
      <div v-else-if="type === 'patient'" class="column is-12 is-full">
        <p>
          <strong>Paciente:</strong> {{patient.code}}<br>
          <strong>Nombre:</strong> {{patient.full_name}}<br>
        </p>
        <b-button @click="patient={}">Elegir Otro Paciente</b-button>
      </div>
      <div v-if="patient.id || type !== 'patient'">
        <div class="columns">
          <div class="column">
            <b-field label="Factura">
              <b-input v-model="invoice"></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-button class="btn is-primary" @click="onAdd">Agregar</b-button>
          </div>
        </div>
        <InvoiceTableCreditNote
            :can-drop="true"
            @drop-item="onDropItem"
            :invoices="invoices"
            @update-subtotal="concept = generateConcept()"
        />
        <b-field label="Concepto">
          <b-input maxlength="200" type="textarea" v-model="concept"></b-input>
        </b-field>
        <b-button @click.prevent="onSubmit" class="btn is-primary">Generar</b-button>
      </div>
    </div>
    <div v-else>
      <div class="columns is-multiline">
        <div class="column is-12">
          <h1 class="title">Nota de Crédito generada</h1>
          <p>
            <strong>RFC: </strong> {{ creditNote.rfc }} <br>
            <strong>Serie: </strong> {{ creditNote.serie }} <br>
            <strong>Folio: </strong> {{ creditNote.folio }} <br>
            <strong>Fecha: </strong> {{ creditNote.datetime }} <br>
          </p>
        </div>
        <div class="column">
          <b-button @click="onRefresh">Generar Nueva Nota de Crédito Grupal</b-button>
        </div>
        <div class="column is-12">
          <iframe class="lapi__iframe" :src="`${endpoint}/order-invoice/${creditNote.uuid}/pdf/download`"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectPatient from "@/components/patient/SelectPatient";
import InvoiceTableCreditNote from "@/components/invoice/InvoiceTableCreditNote";
export default {
  name: "CreditNoteGroup",
  components: {SelectPatient, InvoiceTableCreditNote},
  props: {
    year: {
      type: String | Number
    },
    branch_office: {
      type: String | Number
    }
  },
  data() {
    return {
      invoices: [],
      invoice: '',
      type: 'patient',
      creditNote: null,
      patient: {},
      serie: this.$auth.user().data_branch_office.serial,
      concept: '',
      in_process: false
    }
  },
  computed: {
    endpoint() {
      return process.env.VUE_APP_API_URL;
    },
    subtotal() {
      let subtotal = 0;
      this.invoices.map((item) => {
        subtotal += Number.parseFloat(item.subtotal);
      });
      return subtotal;
    },
  },
  methods: {
    onAdd() {
      const founds = this.invoices.filter((item) => {
        return item.folio.toString() === this.invoice.toString()
      });
      //solo permitir una factura por temas de programación de terminal
      if (founds.length === 0 && this.invoices.length === 0) {
        let filters = {};
        if(this.type === 'patient'){
          filters['patient'] = this.patient.code;
        }else{
          filters['isGeneral'] = true;
        }
        this.$loader.show();
        this.$http.get(`/order-invoice/${this.serie}/${this.invoice}`, {params: filters}).then(({data}) => {
          const invoice = data.data;
          this.invoices.push(invoice);
          this.invoices = Object.assign([], this.invoices);
          this.concept = this.generateConcept();
        }).catch(error => {
          this.$alfalab.showError(error);
        }).finally(() => {
          this.$loader.hide();
          this.invoice = '';
        });
      } else if(this.invoices.length !== 0) {
        this.$toast.error("Solo se puede generar una nota de crédito de una factura");
      }else{
        this.$toast.error("La factura indicada ya se encuentra registrada");
      }
    },
    onDropItem(index) {
      const item = this.invoices[index];
      this.invoices.splice(index, 1);
      this.invoices = this.invoices.slice(0);
      this.concept = this.generateConcept();
    },
    onSubmit(){
      if(!this.in_process){
        this.in_process = true;
        this.$loader.show();
        const params = {
          branch_office_id: this.branch_office,
          invoices: this.invoices,
          concept: this.concept
        }
        this.$http.post('credit-note', params).then(({data}) => {
          this.$toast.success(data.message);
          this.creditNote = data.credit_note;
        }).catch(error => {
          this.$alfalab.showError(error);
        }).finally(() => {
          this.in_process = false;
          this.$loader.hide();
        })
      }
    },
    onRefresh() {
      this.invoice = '';
      this.invoices = [];
      this.creditNote = null;
      this.concept = '';
      this.patient = {};
    },
    generateConcept(){
      let result = 'APLICADA A LA(S) FACTURAS(S) ';
      result += this.invoices.map(item => {
        return item.folio;
      }).join(',');

      if(this.invoices.length > 0){
        result += ' del ' + this.invoices[0].datetime + ' por $' + Number.parseFloat(this.subtotal);
      }
      return result;
    }
  }
}
</script>

<style scoped>
.lapi__iframe {
  width: 100%;
  height: 60vh;
}
</style>
