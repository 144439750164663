<template>
  <article class="panel is-primary">
    <p class="panel-heading">
      Documentos Relacionados
    </p>
    <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
      <thead>
      <tr>
        <th>Fecha</th>
        <th>Serie</th>
        <th>Folio</th>
        <th>Concepto</th>
        <th>Importe</th>
        <th>I.V.A.</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, key) in documents" :key="key">
        <td>{{ item.date | date }}</td>
        <td>{{ item.serie }}</td>
        <td>{{ item.folio }}</td>
        <td>{{ item.concept }}</td>
        <td>{{ item.total | currency }}</td>
        <td>{{ item.tax_rate }}</td>
        <td>
          <button @click="selectedDocument = item.uuid; showPDF = true"
             class="button has-icon is-primary"
             type="button">
            <b-icon icon="file-pdf"></b-icon>
          </button>
        </td>
      </tr>
      <template v-for="item in index">
        <tr class="tr__empty">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </template>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>{{ total | currency }}</td>
        <td></td>
        <td></td>
      </tr>
      </tbody>
    </table>
    <b-modal v-model="showPDF">
      <iframe class="lapi__iframe" :src="`${endpoint}/order-invoice/${selectedDocument}/pdf/download`"></iframe>
    </b-modal>
  </article>
</template>

<script>
export default {
  name: "DocumentsTable",
  props: {
    documents: Array,
  },
  data() {
    return {
      limit: 4,
      showPDF: false,
      selectedDocument: ''
    }
  },
  computed: {
    index() {
      const items = [];
      for (let i = this.documents.length; i < this.limit; i++) {
        items.push(i);
      }
      return items;
    },
    total() {
      let total = 0;
      this.documents.map((item) => {
        total += item.total;
      });
      return total;
    },
    endpoint() {
      return process.env.VUE_APP_API_URL;
    }
  }
}
</script>

<style scoped>
.lapi__iframe{
  width: 100%;
  height: 80vh;
}
</style>
