<template>
  <div>
    <form @submit.prevent="onFind">
      <div class="columns">
        <div class="column">
          <div class="field">
            <b-radio
                @input="onChangeType"
                native-value="E"
                v-model="type"
            >
              Nota de Crédito
            </b-radio>
            <b-radio
                @input="onChangeType"
                v-model="type"
                native-value="I"
            >
              Factura
            </b-radio>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Serie">
            <b-input v-model="serie" :disabled="true"></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Folio">
            <b-input v-model="folio"></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-button native-type="submit">Buscar</b-button>
        </div>
      </div>
    </form>
    <br>
    <b-modal v-model="showPDF">
      <iframe class="lapi__iframe" :src="`${endpoint}/order-invoice/${invoice.uuid}/pdf/download`"></iframe>
    </b-modal>
    <b-modal v-model="showXML">
      <iframe class="lapi__iframe" :src="`${endpoint}/order-invoice/${invoice.uuid}/xml/download`"></iframe>
      <a target="_blank" download class="button is-primary" :href="`${endpoint}/order-invoice/${invoice.uuid}/xml/download?direct=1`">Descargar XML</a>
    </b-modal>
    <div v-if="invoice.uuid">
      <div class="columns">
        <div class="column">
          <p><strong>Serie:</strong> {{invoice.serie}}</p>
          <p><strong>Folio:</strong> {{invoice.folio}}</p>
          <p><strong>Concepto:</strong> {{invoice.concept}}</p>
          <p v-if="invoice.cancelled_at"><strong>Fecha de Cancelación: </strong> {{invoice.cancelled_at}}</p>
          <p v-if="invoice.motivo"><strong>Motivo de Cancelación: </strong> {{invoice.motivo}}</p>
        </div>
      </div>
      <template v-if="$alfalab.canCancelInvoice($auth.user())">
        <div class="columns"  v-if="!invoice.cancelled_at">
          <div class="column is-4">
            <b-field label="Motivo">
              <b-select placeholder="Motivo de cancelación" v-model="motive">
                <option value="01">[01] Comprobantes emitidos con errores con relación.</option>
                <option value="02">[02] Comprobantes emitidos con errores sin relación.</option>
                <option value="03">[03] No se llevó a cabo la operación.</option>
                <option value="04">[04] Operación nominativa relacionada en una factura global.</option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-4" v-if="motive === '01'">
            <b-field label="UUID Nueva Factura">
              <b-input v-model="uuid_replace"></b-input>
            </b-field>
          </div>
          <div class="column is-4" >
            <b-button class="is-danger" @click="() => {this.modalConfirm = true}">Cancelar CFDI</b-button>
          </div>
        </div>
      </template>
      <div class="columns">
        <div class="column">
          <b-button @click="() => {this.showPDF = true;}">Ver PDF</b-button>
        </div>
        <div class="column">
          <b-button @click="() => {this.showXML = true;}">Ver XML</b-button>
        </div>
        <div class="column">
          <b-field label="Email">
            <b-input type="email" v-model="email"/>
          </b-field>
        </div>
        <div class="column">
          <b-button @click="sendEmail">Enviar por correo</b-button>
        </div>
      </div>
    </div>
    <br>
    <InvoiceTable :orders="invoice.orders" :invoice="invoice"/>
    <br>
    <DocumentsTable
        v-if="documents && documents.length > 0"
        :documents="documents"
    />
    <b-modal
        :active.sync="modalConfirm"
        destroy-on-hide
        :can-cancel="true"
        :has-modal-card="true">
      <ModalConfirm
          title="Cancelar Documento Fiscal"
          cancel-text="No"
          confirm-text="Cancelar Documento Fiscal"
          content="¿Esta seguro de cancelar el documento fiscal?.<br/>Esta acción no se puede deshacer"
          :on-confirm="onCancel"
      />
    </b-modal>
  </div>
</template>

<script>
import InvoiceTable from "@/components/invoice/InvoiceTable";
import DocumentsTable from "@/components/invoice/DocumentsTable";
import ModalConfirm from "@/components/ModalConfirm";
export default {
  name: "SearchInvoice",
  components: {InvoiceTable, DocumentsTable, ModalConfirm},
  props: {
    year: {
      type: String | Number
    },
    branch_office: {
      type: String | Number
    }
  },
  data() {
    return {
      type: 'I',
      invoice: {
        orders: []
      },
      serie: this.$auth.user().data_branch_office.serial,
      folio: '',
      email: '',
      showPDF: false,
      showXML: false,
      modalConfirm: false,
      inProgress: false,
      documents: null,
      motive: '',
      uuid_replace: ''
    }
  },
  computed: {
    endpoint() {
      return process.env.VUE_APP_API_URL;
    }
  },
  methods: {
    onFind(){
      this.$loader.show();
      this.documents = null;
      this.invoice = {
        orders: []
      };
      this.email = '';
      this.$http.get(`/order-invoice/${this.serie}/${this.folio}?no_iva=1`).then(({data}) => {
        this.invoice = data.data;
      }).catch(errors => {
        console.log(errors);
        this.$toast.error(errors.message);
      }).finally(() => {
        this.$loader.hide();
      })
    },
    onChangeType(value){
      this.invoice = {
        orders: []
      }
      this.documents = null;
      if(value === 'E'){
        this.serie = this.$auth.user().data_branch_office.serial_credit_note;
      }else{
        this.serie = this.$auth.user().data_branch_office.serial;
      }
    },
    sendEmail(){
      this.$loader.show();
      this.$http.post(`/invoice/email/${this.invoice.serie}/${this.invoice.folio}`, {email:this.email}).then(({data}) => {
        this.$toast.success(data.message);
      }).catch(errors=> {
        this.$alfalab.showError(errors);
      }).finally(() => {
        this.$loader.hide();
      })
    },
    onCancel(){
      if(!this.inProgress){
        this.inProgress = true;
        this.modalConfirm = false;
        this.$loader.show();
        this.$http.post(`/order-invoice/${this.invoice.uuid}/cancel`, {
          motive: this.motive,
          uuid_replace: this.uuid_replace
        }).then(({data}) => {
          this.$toast.success(data.message);
          try{
            this.invoice = Object.assign({}, this.invoice, {cancelled_at :data.CancelationDate});
          }catch (e){
            console.error(e);
          }
        }).catch(errors => {
          this.$alfalab.showError(errors);
        }).finally(() => {
          this.inProgress = false;
          this.$loader.hide();
          this.motive = '';
          this.uuid_replace = '';
        })
      }
    }
  }
}
</script>

<style scoped>
.lapi__iframe{
  width: 100%;
  height: 80vh;
  background: white;
}
</style>
