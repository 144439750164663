<template>
	<section class="section">
		<content-placeholders
			v-if="isLoading"
		>
      <content-placeholders-heading :img="true" />
      <content-placeholders-text :lines="3" />
		</content-placeholders>
    <template v-if="!isLoading">
      <div class="box">
        <div class="columns is-mobile is-centered">
          <div class="column">
            <p class="bd-notification is-primary">
              <b-field label="Sucursal" style="min-width: 150px">
                <b-select  expanded disabled v-model="branchoffice">
                  <option :value="$auth.user().active_branch_office">{{$auth.user().data_branch_office.name}}</option>
                </b-select>
              </b-field>
            </p>
          </div>
          <div class="column">
            <p class="bd-notification is-primary">
              <b-field label="Año" style="min-width: 150px">
                <b-select  expanded v-model="selectedYear">
                  <option :value="year.key" v-for="year in years" :key="year.key">{{year.value}}</option>
                </b-select>
              </b-field>
            </p>
          </div>
        </div>
        <div class="columns is-mobile is-centered">
          <div class="column is-full">
            <b-tabs v-model="activeTab">
              <b-tab-item label="Nueva Factura">
                <InvoiceGroup :year="selectedYear" :branch_office="branchoffice"/>
              </b-tab-item>
              <b-tab-item label="Nueva Nota de Crédito" v-if="false">
                <CreditNoteGroup :year="selectedYear" :branch_office="branchoffice"/>
              </b-tab-item>
              <b-tab-item label="Consulta">
                <SearchInvoice :year="selectedYear" :branch_office="branchoffice" />
              </b-tab-item>
            </b-tabs>
          </div>
        </div>
      </div>
    </template>
	</section>
</template>

<script>
import InvoiceGroup from "@/components/invoice/InvoiceGroup";
import SearchInvoice from "@/components/invoice/SearchInvoice";
import CreditNoteGroup from "@/components/invoice/CreditNoteGroup";

export default {
	name: "Index",
  components: {InvoiceGroup, SearchInvoice, CreditNoteGroup},
	data() {
		return {
			apiUrl: '',
			isLoading: false,
      activeTab: 0,
      selectedYear: new Date().getFullYear() % 10,
      branchoffice: this.$auth.user().active_branch_office
		}
	},
  computed: {
    years(){
	    const current = new Date().getFullYear();
	    const years = [];
	    years.push({key: (current % 10), value: current});
      years.push({key: ((current-1) % 10), value: current-1});
      return years;
    }
  },
}
</script>

<style scoped>
.tr__empty{
  height: 28px;
}
</style>
