<template>
  <article class="panel is-primary">
    <p class="panel-heading">
      Facturas
    </p>
    <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
      <thead>
      <tr>
        <th v-if="canDrop"></th>
        <th>Serie</th>
        <th>Factura</th>
        <th>Información de la Factura</th>
        <th>Subtotal</th>
        <th>% IVA</th>
        <th>% Desc</th>
        <th>Total</th>
        <th>UUID</th>
        <th>M. Pago</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, key) in invoices" :key="key">
        <td v-if="canDrop">
          <b-button @click="$emit('drop-item', key)">
            <b-icon
                icon="window-close"
                size="is-small">
            </b-icon>
          </b-button>
        </td>
        <td>{{ item.serie }}</td>
        <td>{{ item.folio }}</td>
        <td>{{ item.concept }}</td>
        <td><b-input @input="$emit('update-subtotal')" type="number" v-model="item.subtotal"/></td>
        <td>{{ item.tax_rate  }}</td>
        <td> </td>
        <td>{{ subtotal * 1.16 | currency }}</td>
        <td>{{ item.uuid}}</td>
        <td>{{item.payment_form}}</td>
      </tr>
      <template v-for="item in index">
        <tr class="tr__empty">
          <td v-if="canDrop"></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </template>
      <tr>
        <td v-if="canDrop"></td>
        <td></td>
        <td></td>
        <td></td>
        <td>{{ subtotal | currency }}</td>
        <td></td>
        <td></td>
        <td>{{ subtotal * 1.16 | currency }}</td>
        <td></td>
        <td></td>
      </tr>
      </tbody>
    </table>
  </article>
</template>

<script>
export default {
  name: "InvoiceTableCreditNote",
  props: {
    invoices: Array,
    canDrop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      limit: 4,
    }
  },
  computed: {
    index() {
      const items = [];
      for (let i = this.invoices.length; i < this.limit; i++) {
        items.push(i);
      }
      return items;
    },
    total() {
      let total = 0;
      this.invoices.map((item) => {
        total += item.total;
      });
      return total;
    },
    subtotal() {
      let total = 0;
      this.invoices.map((item) => {
        total += Number.parseFloat(item.subtotal);
      });
      return total;
    }
  }
}
</script>

<style scoped>

</style>
